import { Directive, HostListener, Input } from '@angular/core';

// declare gtag as global function as it is defined in the index.html asynchronously
declare var gtag: Function;

@Directive({
  selector: '[gaTag]'
})
export class GoogleAnalyticsEventDirective {
  @Input('gaTag') gaTag: string = 'NOT_DEFINED';
  private readonly _isGTagAvailable: boolean = false;

  constructor() {
    this._isGTagAvailable = typeof gtag === 'function';
    if(!this._isGTagAvailable) {
      console.warn(`Google Analytics is not initialized at directive instantiation with tag: ${this.gaTag}`);
    }
  }

  // Listen for click events on the host element
  @HostListener('click')
  onClick() {
    if (this._isGTagAvailable) {
      const date = new Date();
      const time = date.toLocaleDateString() + '_' + date.toLocaleTimeString()
      // Send the Google Analytics event when the element is clicked
      gtag('event', 'click', {
        'GA-tag': this.gaTag,
        'dateTime': time
      });
    } else {
      console.warn('Google Analytics is not initialized');
    }
  }
}
